import React, { useEffect } from 'react';
import './PhotoCapture.css';
import { debounce } from 'lodash';

const calcDistance = (a: number[], b: number[]) => {
  return Math.sqrt(Math.pow(b[0] - a[0], 2) + Math.pow(b[1] - a[1], 2))
}
interface Note {
  coords: number[];
  state?: string;
}

// From: https://developer.mozilla.org/en-US/docs/Web/API/Media_Streams_API/Taking_still_photos
function PhotoCapture() {
  let isCameraOpen = false;
  let cameraStream: MediaStream | null;
  let notes: Note[] = [];
  let circleSize = 20;

  // Fill the photo with an indication that none has been
  // captured.
  const clearPhoto = () => {
    const photo = document.getElementById('photo');
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    var context = canvas.getContext('2d');
    if (!(photo && canvas && context)) return;

    context.fillStyle = "#AAA";
    context.fillRect(0, 0, canvas.width, canvas.height);

    var data = canvas.toDataURL('image/png');
    photo.setAttribute('src', data);
    photo.style.display = "none";
    localStorage.removeItem("photo");
    localStorage.removeItem("notes");
    notes = [];
    drawNotes();
  }

  // Capture a photo by fetching the current contents of the video
  // and drawing it into a canvas, then converting that to a PNG
  // format data URL. By drawing it on an offscreen canvas and then
  // drawing that to the screen, we can change its size and/or apply
  // other changes before drawing it.
  const takePhoto = (existingPhotoData?: string | null) => {
    const photo = document.getElementById('photo');
    const video = document.getElementById('video') as HTMLVideoElement;
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    var context = canvas.getContext('2d');
    if (!(photo && video && canvas && context)) return;

    try {
      let data;
      if (existingPhotoData) {
        data = existingPhotoData
      } else {
        const {width, height} = video.getBoundingClientRect();
        canvas.width = width;
        canvas.height = height;
        context.drawImage(video, 0, 0, width, height);
        data = canvas.toDataURL('image/png');
        localStorage.setItem("photo", data)
      }
      photo.setAttribute('src', data);
      photo.style.display = "block";
    } catch (e) {
      console.log("Unable to takePhoto: ", e);
    }
  }

  // Open the device camera and stream the contents to the video.
  const openCamera = () => {
    const video = document.getElementById('video') as HTMLVideoElement;
    if (!(video && navigator && navigator.mediaDevices && !isCameraOpen)) return;

    // Get the camera stream and display it in the video element.
    navigator.mediaDevices.getUserMedia({video: {facingMode: 'environment'}, audio: false})
      .then(function(stream) {
        cameraStream = stream;
        isCameraOpen = true;
        video.srcObject = stream;
        video.play();
      })
      .catch(function(err) {
        // Sometimes this happens when camera permission is denied.
        console.log("An error occurred: " + err);
      });

    video.style.display = "block";
  }

  // Close the device camera and reset the UI.
  const closeCamera = () => {
    const video = document.getElementById('video') as HTMLVideoElement;
    if (!(video && isCameraOpen && cameraStream)) return;

    cameraStream.getTracks().forEach(track => track.stop()) // seems to work
    cameraStream = null;
    isCameraOpen = false;
    video.style.display = "none";
  }

  const showExistingPhoto = () => {
    const exitingCircleSize = localStorage.getItem("circleSize");
    if (exitingCircleSize) {
      try {
        circleSize = parseInt(exitingCircleSize);
      } catch (e) {
        circleSize = 20;
      }
      const slider = document.getElementById("circleSizeSlider") as HTMLInputElement;
      if (slider as HTMLInputElement) {
        slider.value = `${circleSize}`;
        slider.dispatchEvent(new Event("change"));
      }
    }

    const existingPhotoData = localStorage.getItem("photo");
    const existingNotesData = localStorage.getItem("notes");
    if (existingPhotoData && existingNotesData) {
      takePhoto(existingPhotoData);
      drawNotes(existingNotesData);
    } else {
      // What if we have notes data but no photo data?  Do I need to clear it?  Or probably not it will get reset by next click?
      clearPhoto();
    }
  }

  const clickHandler = (ev: React.MouseEvent) => {
    const photo = document.getElementById('photo');
    const notesCanvas = document.getElementById('notesCanvas') as HTMLCanvasElement;
    var context = notesCanvas.getContext('2d');
    if (!(photo && notesCanvas && context)) return;

    // Get coordinates of main displayed photo.
    const coords = photo.getBoundingClientRect();
    const relativeX = ev.clientX - coords.x;
    const relativeY = ev.clientY - coords.y;
    const newNote = {coords: [relativeX, relativeY]};

    // Check if we clicked inside an existing Note/circle.
    const {clickedNotes, remainingNotes} = notes.reduce((acc, note) => {
      const distance = calcDistance(newNote.coords, note.coords)
      if (distance < circleSize) {
        acc.clickedNotes.push(note)
      } else {
        acc.remainingNotes.push(note)
      }
      return acc;
    }, {clickedNotes: [] as Note[], remainingNotes: [] as Note[]});

    if (clickedNotes.length > 0) {
      // If we clicked a Note, cycle it's state: none (green) -> "red" (red) -> deleted
      const notesToKeep = clickedNotes.reduce((acc, note) => {
        if (!note.state) {
          note.state = "red";
          acc.push(note);
        }
        return acc;
      }, [] as Note[])
      notes = remainingNotes.concat(notesToKeep);
    } else {
      // Didn't click a Note, add a new Note.
      notes.push(newNote);
    }
    localStorage.setItem("notes", JSON.stringify(notes));
    drawNotes();
  }

  const drawNotes = (existingNotesData?: string) => {
    const photo = document.getElementById('photo');
    const notesCanvas = document.getElementById('notesCanvas') as HTMLCanvasElement;
    var context = notesCanvas.getContext('2d');
    if (!(photo && notesCanvas && context)) return;

    // Get coordinates of main displayed photo.
    const coords = photo.getBoundingClientRect();

    // Set notes canvast to the same size to overlay our circles.
    // Does this also clear the content??!?
    notesCanvas.width = coords.width;
    notesCanvas.height = coords.height;

    // Draw all of our notes.
    if (existingNotesData) {
      try {
        notes = JSON.parse(existingNotesData);
      } catch (e) {
        console.log('Unable to parse existing notes: ', e);
        notes = [];
      }
    }
    notes.forEach((note) => {
      if (!context) return;
      const color = note.state === "red" ? '#cf0606' : '#50f27b';

      context.beginPath();
      context.arc(note.coords[0], note.coords[1], circleSize, 0, 2 * Math.PI, false);
      // context.fillStyle = 'green';
      // context.fill();
      context.lineWidth = circleSize >= 20 ? 5 : 1;
      context.strokeStyle = color;
      context.stroke();
    })
  }

  const saveClimb = async () => {
    let gameId = localStorage.getItem("gameId");
    const existingPhotoData = localStorage.getItem("photo");
    const existingNotesData = localStorage.getItem("notes");
    console.log('Existing gameId, data: ', gameId, existingPhotoData, existingNotesData);

    // Existing gameId, update that game.
    // Note: this can be abused to step on other peoples' climbs.
    if (gameId) {
      const response = await fetch('/api/saveClimb', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ gameId,  photo: existingPhotoData, notes: existingNotesData}),
      })
      console.log("Had a game ID: ", await response.json())
    } else {
      // No gameId, need to make a new game.
      const response = await fetch('/api/saveClimb', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ photo: existingPhotoData, notes: existingNotesData}),
      })
      console.log("No game id, making a new game: ", await response.json())
    }
  }

  const _changeCircleSize = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    try {
      circleSize = parseInt(ev.target.value);
    } catch (e) {
      console.log("Unable to change circle size: ", e);
      circleSize = 20
    }
    localStorage.setItem("circleSize", `${circleSize}`);
    drawNotes();
  }

  const changeCircleSize = debounce(_changeCircleSize, 200, {trailing: true});

  useEffect(() => {
    showExistingPhoto();
  })

  return (
    <div id="photoCapture">
      <h1>
        Elimination / Add-On
      </h1>

      <div id='controlButtons'>
        <button id="startButton" onClick={() => {openCamera()}}>Start Camera</button>
        <button id="photoButton" onClick={() => {takePhoto()}}>Take Photo</button>
        <button id="clearButton" onClick={() => {clearPhoto()}}>Clear Photo</button>
        <button id="closeButton" onClick={() => {closeCamera()}}>Close Camera</button>
        <button id="saveButton" onClick={() => {saveClimb()}}>Save Climb</button>
      </div>

      <div id="captureArea" onClick={clickHandler}>
        <video id="video">Video stream not available.</video>
        <canvas id="canvas" style={{display: "none"}}></canvas>
        <canvas id="notesCanvas"></canvas>
        <img id="photo" alt="The screen capture will appear in this box."></img>
      </div>

      <div id="secondaryButtons">
        <input type="range" min="1" max="100" defaultValue={circleSize} id="circleSizeSlider" onChange={changeCircleSize}></input>
      </div>

      <p>
        Thanks and credit to the article: <a href="https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Taking_still_photos"> Taking still photos with WebRTC</a>.  Visit to learn more about the technologies used here.
      </p>
    </div>
  );
}

export default PhotoCapture;
